import React, { useState, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import SlideHeaderContent from "../elements/slide-header-content";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import Loadable from "@loadable/component";
import { useForm } from "react-hook-form";
import Input from "../elements/input";
import ControlledInput from "../elements/controlled-input";
import { LandingPrivacy } from "../privacy-texts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import elencoComuni from "../../data/gi_db_comuni/gi_comuni.json";

import {
  tipologieList,
  tipologieVerifica,
  fasciaOrariaNoPreferenzaOptions,
  submitForm,
  overlayTextParser,
} from "../../utility/config-form-contatti";
import withPreview from "../../utility/with-preview";
import { validatePhoneIT } from "../../utility/validate-form";
const FORM_VENDIBILITA_BASE_URL = process.env.GATSBY_FORM_VENDIBILITA;

const PrivacyModal = Loadable(() => import("./privacy-modal"));

const NewGenericLanding = ({ data, location }) => {
  const {
    desktopXlImg,
    desktopLImg,
    tabletImg,
    mobileImg,
    htmlTextDesktop,
    htmlTextMobile,
    logoDesktop,
    showProductStars,
    cdownDate,
    formTitle,
    stickyBarText,
  } = data;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [comuni, setComuni] = useState([]);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [response, setResponse] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [codiciComuni, setCodiciComuni] = useState(new Map());
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, touchedFields, isValid, isDirty },
  } = useForm({ mode: "onTouched" });
  const comuneWatch = watch("comune");

  const headerImg = desktopXlImg?.node
    ? withArtDirection(
        getImage(desktopXlImg?.node),
        [
          mobileImg?.node && {
            media: "(max-width: 768px)",
            image: getImage(mobileImg.node),
          },
          tabletImg?.node && {
            media: "(max-width: 992px)",
            image: getImage(tabletImg.node),
          },
          desktopLImg?.node && {
            media: "(max-width: 1200px)",
            image: getImage(desktopLImg.node),
          },
        ].filter(Boolean)
      )
    : null;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    const loadComuni = async () => {
      const listaComuni = [];

      const fetchPath =
        tipologieList[
          formTitle?.content?.vendibilita?.value?.[0]
            ? formTitle.content.vendibilita.value[0]
            : "pesozero"
        ];
      if (fetchPath) {
        Array.from(elencoComuni).forEach(function (_comune) {
          let optionKey =
            _comune.denominazione_ita.toUpperCase() + " (" + _comune.sigla_provincia + ")";
          listaComuni.push(optionKey);
          setCodiciComuni(codiciComuni.set(optionKey, _comune.codice_istat));
        });
        setComuni(listaComuni);
      }
    };
    loadComuni();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [formTitle, codiciComuni]);

  const validateComune = useCallback(
    async (value) => {
      if (value.length < 2) {
        return "";
      }
      const comune = value.trim().toUpperCase();
      const codiceComune = codiciComuni.get(comune);

      if (!comuni.includes(comune)) {
        return "Ci spiace, il comune che hai inserito sembra non esistere o è una frazione";
      } else {
        try {
          console.log("tipologieVerifica", tipologieVerifica);
          const fetchPath =
            tipologieVerifica[
              formTitle?.content?.vendibilita?.value?.[0]
                ? formTitle.content.vendibilita.value[0]
                : "pesozero"
            ];
          const response = await fetch(
            `${FORM_VENDIBILITA_BASE_URL}/contatti.contact${fetchPath}${codiceComune}`
          );
          if (!response.ok) {
            throw new Error(`Err ${response.status} ${response.statusText}`);
          }
          const data = await response.json();
          const dispData = data ? JSON.parse(data) : {};
          if (dispData.copertura === "Si") {
            return true;
          }
        } catch (err) {
          console.error("Error validating comune: ", err);
        }
        return "Ci spiace, non siamo presenti nel tuo comune";
      }
    },
    [comuni, formTitle, codiciComuni]
  );

  const submit = async (values) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formCasa");

    try {
      const result = await submitForm(
        { ...values, grecaptcha: token },
        formTitle?.content,
        location
      );
      console.log("Submit result: ", result);
      const submitEvent = new CustomEvent("form-landing-success", {
        detail: {},
      });
      document.dispatchEvent(submitEvent);
      setResponse("ok");
    } catch (err) {
      console.error("Error submit: ", err);
      setResponse("ko");
    }
  };

  return (
    <section className="section new-generic-landing position-relative">
      <div className="new-generic-landing__header">
        <SlideHeaderContent
          headerImg={headerImg}
          headerRatio={mobileImg?.node?.probeInfo?.aspectRatio}
          logo={logoDesktop?.node}
          title={htmlTextDesktop?.value}
          titleMobile={htmlTextMobile?.value}
          productStars={{
            show: showProductStars?.value,
            prodId: showProductStars?.content?.dataProductId?.value,
            showText: showProductStars?.content?.showText?.value, //TODO: fix showtext = false
          }}
          countdown={cdownDate}
        />
      </div>
      <div
        className="new-generic-landing__card-container d-flex flex-column align-items-center"
        id="new-generic-landing-form-wrapper"
      >
        <div className="new-generic-landing__card d-flex flex-column align-items-center">
          <div
            className="new-generic-landing__card-title text-center"
            dangerouslySetInnerHTML={{ __html: formTitle?.value }}
          />
          <div
            className="new-generic-landing__card-subtitle text-center"
            dangerouslySetInnerHTML={{ __html: formTitle?.content?.formSubtitle?.value }}
          />
          {response ? (
            <div
              className="new-generic-landing__responseMsg d-flex justify-content-center align-items-center text-center"
              dangerouslySetInnerHTML={{
                __html:
                  response === "ok"
                    ? formTitle?.content?.thankYouMessage?.value
                    : formTitle?.content?.errorMessage?.value,
              }}
            />
          ) : (
            <form className="form w-100" onSubmit={handleSubmit(submit)}>
              <Input
                name="name"
                register={register}
                validation={{
                  required: { value: true, message: "Specificare un nome" },
                  pattern: {
                    value: /^[a-z\u00C0-\u017F'\s]+$/i,
                    message: "Specificare un nome (solo caratteri e/o spazi).",
                  },
                }}
                placeholder="Nome"
                movingLabel={false}
                error={errors.name}
              />
              <Input
                name="surname"
                register={register}
                validation={{
                  required: { value: true, message: "Specificare un cognome" },
                  pattern: {
                    value: /^[a-z\u00C0-\u017F'\s]+$/i,
                    message: "Specificare un nome (solo caratteri e/o spazi).",
                  },
                }}
                placeholder="Cognome"
                movingLabel={false}
                error={errors.surname}
              />
              <Input
                name="email"
                register={register}
                validation={{
                  required: { value: true, message: "Specificare un indirizzo e-mail" },
                  pattern: {
                    value:
                      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                    message: "Specificare un indirizzo e-mail valido",
                  },
                }}
                placeholder="Email"
                movingLabel={false}
                error={errors.email}
              />
              <Input
                name="tel"
                register={register}
                validation={{ validate: (value) => validatePhoneIT(value, 9, 20, true) }}
                placeholder="Telefono"
                movingLabel={false}
                error={errors.tel}
              />
              <ControlledInput
                name="comune"
                control={control}
                required={{ value: true, message: "Campo obbligatorio" }}
                validate={validateComune}
                options={comuni}
                movingLabel={false}
                minChars={1}
                label="Comune"
                error={errors.comune}
                touched={touchedFields.comune}
                isDirty={comuneWatch}
              />
              <p className="new-generic-landing__card-text">
                Scegli una fascia oraria in cui sarai in casa
              </p>
              <Input
                type="select"
                name="fasciaOraria"
                defaultValue={true}
                register={register}
                placeholder="Orario di ricontatto"
                showPlaceholderOpt={false}
                options={fasciaOrariaNoPreferenzaOptions}
                error={errors.fasciaOraria}
              />
              <div className="mt-3">
                <Input
                  type="checkbox"
                  name="personalData"
                  register={register}
                  validation={{ required: true }}
                  label={overlayTextParser(formTitle.content.termsText.value, setShowPrivacyModal)}
                  error={errors.personalData}
                />
              </div>

              {formTitle?.content?.ctaText?.value && (
                <div className="mt-3 text-center">
                  <input
                    type="submit"
                    className="form__submit cta-link"
                    value={formTitle.content.ctaText.value}
                    disabled={!isDirty || !isValid}
                  />
                </div>
              )}
            </form>
          )}
        </div>
      </div>
      <PrivacyModal
        show={showPrivacyModal}
        setShow={setShowPrivacyModal}
        scrollbar={false}
        modalStyle={{
          top: "140px",
          bottom: "0",
          maxWidth: "1000px",
        }}
      >
        <LandingPrivacy
          innerContent={formTitle.content.termsText.content.privacyPolicy.value}
          setShow={setShowPrivacyModal}
        />
      </PrivacyModal>
      {stickyBarText?.value &&
        stickyBarText?.content?.stickyBarTitle?.value &&
        scrollPosition > 580 && (
          <div className="container-fluid px-0">
            <div className="sticky-footer">
              <div className="d-flex flex-row justify-content-around align-items-center">
                <div className="d-none d-md-flex flex-row">
                  <p
                    className="sticky-footer__intro px-2"
                    dangerouslySetInnerHTML={{ __html: stickyBarText.value }}
                  />
                  <p
                    className="sticky-footer__title px-2"
                    dangerouslySetInnerHTML={{
                      __html: stickyBarText.content?.stickyBarTitle?.value,
                    }}
                  />
                  {stickyBarText?.content?.stickyBarPrice?.value && (
                    <div
                      className="d-none d-lg-block px-2"
                      dangerouslySetInnerHTML={{
                        __html: stickyBarText.content?.stickyBarPrice?.value,
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column flex-md-row text-center">
                  {stickyBarText?.content?.ctaSb?.map(
                    (cta, i) =>
                      cta?.value && (
                        <div className="d-flex flex-column flex-md-row text-center px-2" key={i}>
                          <a
                            href={
                              cta.content?.linkCtaSb?.value || "#new-generic-landing-form-wrapper"
                            }
                            title={cta.value}
                            className={
                              "cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 " +
                              cta.content?.tipoCtaSb?.value
                            }
                            style={{
                              "--coloreCtaSb": cta?.content?.coloreCtaSb?.value,
                              "--coloreCtaHoverSb": cta?.content?.coloreCtaHoverSb?.value,
                              "--coloreCtaClickSb": cta?.content?.coloreCtaClickSb?.value,
                            }}
                          >
                            {cta.value}
                          </a>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

export default withPreview(NewGenericLanding);
export const fragment = graphql`
  fragment NewGenericLandingFragment on LiferayNewGenericLanding {
    liferayFields {
      siteId
      articleId
    }
    desktopXlImg {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    desktopLImg {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    tabletImg {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    mobileImg {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
        probeInfo {
          aspectRatio
        }
      }
    }
    logoDesktop {
      node {
        gatsbyImageData(width: 300)
      }
    }
    htmlTextDesktop {
      value
    }
    htmlTextMobile {
      value
    }
    showProductStars {
      value
      content {
        dataProductId {
          value
        }
        showText {
          value
        }
      }
    }
    formTitle {
      value
      content {
        formSubtitle {
          value
        }
        termsText {
          value
          content {
            privacyPolicy {
              value
            }
          }
        }
        ctaText {
          value
          content {
            ctaColor {
              value
            }
          }
        }
        thankYouMessage {
          value
        }
        errorMessage {
          value
        }
        vendibilita {
          value
        }
        prodottoInteressato {
          value
        }
        provUrlType {
          value
        }
      }
    }
    cdownDate {
      value
      content {
        cdownColor {
          value
        }
      }
    }
    stickyBarText {
      value
      content {
        stickyBarTitle {
          value
        }
        stickyBarPrice {
          value
        }
        ctaSb {
          value
          content {
            linkCtaSb {
              value
            }
            coloreCtaSb {
              value
            }
            coloreCtaHoverSb {
              value
            }
            coloreCtaClickSb {
              value
            }
            tipoCtaSb {
              value
            }
          }
        }
      }
    }
    sbScript {
      value
    }
  }
`;
